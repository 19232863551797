import { Observable, of } from "rxjs";
import {
  ISelectItem,
  TDictionaryField
} from "ngx-strong-frontend-lib/interfaces";
import { UniversalGridUtilsService } from 'ngx-strong-frontend-lib/grid/services/universal-grid-utils';
import { TranslateService } from "@ngx-translate/core";
import { share } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { FilterType } from "ngx-strong-frontend-lib/enums";
import { DateTimeService } from "ngx-strong-frontend-lib/services/date-time";

@Injectable()
export class AuthUniversalGridUtilsService extends UniversalGridUtilsService {

  constructor(
    protected translateService: TranslateService,
    protected dateTimeService: DateTimeService
  ) {
    super(translateService, dateTimeService);
  }

  public getDictionaryType(dictCode: string): FilterType.select | FilterType.dictionary {
    return []
      .includes(dictCode)
      ? FilterType.select
      : FilterType.dictionary;
  }

  public getSelectList(dictCode: string): Observable<ISelectItem[]> {
    let result: Observable<ISelectItem[]> = of([]);
    return result.pipe(share());
  }

  public getDictionary(dictCode: string): TDictionaryField {
    let result: TDictionaryField = null;
    return result;
  }
}
