import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';
import { INotifications, IUserInfo } from '@core/interfaces/user';

@Injectable({
  providedIn: 'root',
})
export class UserApiService {
  constructor(private HttpClient: HttpClient) {}

  /**
   * Получить информацию о текущем пользователе
   * @returns
   */
  public getUserInfo(): Observable<IUserInfo> {
    return this.HttpClient.get<IUserInfo>(
      `${environment.API_URL}/user/profile`,
    ).pipe(share());
  }

  /**
   * Обновить настройки уведомлений
   * @param notifications
   * @returns
   */
  public updateUserNotifications(
    notifications: INotifications,
  ): Observable<any> {
    return this.HttpClient.put<any>(
      `${environment.API_URL}/user/notifications`,
      notifications,
    ).pipe(share());
  }

  /**
   * Изменить язык системы для пользователя
   * @param languageId ID языка
   * @returns
   */
  public updateUserLanguage(languageId: number) {
    return this.HttpClient.put(
      `${environment.API_URL}/user/language/${languageId}`,
      {},
    ).pipe(share());
  }
}
