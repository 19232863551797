import { AppRoutingModule } from './app.routing';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClientModule } from '@angular/common/http';
import { ContextMenuModule } from '@perfectmemory/ngx-contextmenu';
import { AppComponent } from '@app/app.component';
import { CoreModule } from '@core/core.module';
import { ComponentLibraryModule } from 'ngx-strong-frontend-lib/module';
import {
  ACCESS_OBJECTS_KEY,
  ACCESS_RIGHTS_KEY,
  AUTH_SYSTEM_PARAMETER_NAME,
} from '@app/app.enums';
import { LazyModuleLoaderComponent } from 'ngx-strong-frontend-lib/components/lazy-module-loader';
import { AuthUniversalGridApiService } from '@core/services/api/auth-universal-grid-api.service';
import { AuthUniversalGridUtilsService } from '@shared/services/auth-universal-grid-utils.service';
import { UniversalGridApiService } from 'ngx-strong-frontend-lib/grid/services/universal-grid-api';
import { UniversalGridUtilsService } from 'ngx-strong-frontend-lib/grid/services/universal-grid-utils';
import {
  NoAccessComponent,
  NoAccessConfig,
} from 'ngx-strong-frontend-lib/components/no-access';
import { SystemParamsService } from './core/services/system-params.service';
import { map } from 'rxjs/operators';
import {
  PageWrongComponent,
  PageWrongConfig,
} from 'ngx-strong-frontend-lib/components/page-wrong';
import {
  PageNotFoundComponent,
  PageNotFoundConfig,
} from 'ngx-strong-frontend-lib/components/page-not-found';
import { LogoComponent } from './shared/components/logo/logo.component';
import { of } from 'rxjs';
import { DynamicImportTranslationLoader } from '@core/services/dynamic-import-translation-loader.service';

import dayjs from 'dayjs';
import dayjsUtc from 'dayjs/plugin/utc';
import dayjsFormats from 'dayjs/plugin/customParseFormat';
import dayjsRu from 'dayjs/locale/ru';
import dayjsQuarter from 'dayjs/plugin/quarterOfYear';
import dayjsIsSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import dayjsIsSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import dayjsDuration from 'dayjs/plugin/duration';
import dayjsArraySupport from 'dayjs/plugin/arraySupport';
import { QuicklinkModule } from 'ngx-quicklink';
dayjs.extend(dayjsQuarter);
dayjs.extend(dayjsUtc);
dayjs.extend(dayjsFormats);
dayjs.extend(dayjsIsSameOrBefore);
dayjs.extend(dayjsIsSameOrAfter);
dayjs.extend(dayjsDuration);
dayjs.extend(dayjsArraySupport);
dayjs.locale(dayjsRu);

function noAccessFactory(
  systemParamsService: SystemParamsService,
): NoAccessConfig {
  return {
    supportPhone$: systemParamsService
      .getPublicSystemParams()
      .pipe(
        map((res) => res?.[AUTH_SYSTEM_PARAMETER_NAME.DEVELOPER_PHONE] ?? ''),
      ),
    supportEmail$: systemParamsService
      .getPublicSystemParams()
      .pipe(
        map((res) => res?.[AUTH_SYSTEM_PARAMETER_NAME.DEVELOPER_EMAIL] ?? ''),
      ),
  };
}
function pageWrongFactory(): PageWrongConfig {
  return {
    homeUrl$: of('/home'),
    homeUrlTarget: 'inner',
    logoComponent: LogoComponent,
  };
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    CoreModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ContextMenuModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: DynamicImportTranslationLoader,
      },
    }),
    AppRoutingModule,
    ComponentLibraryModule.forRoot({
      menuConfig: null,
      accessObjectsStorageKey: ACCESS_OBJECTS_KEY,
      accessRightsStorageKey: ACCESS_RIGHTS_KEY,
      userAccess: null,
    }),
    LazyModuleLoaderComponent,
    NoAccessComponent,
    PageWrongComponent,
    PageNotFoundComponent,
    QuicklinkModule,
  ],
  providers: [
    {
      provide: NoAccessConfig,
      useFactory: noAccessFactory,
      deps: [SystemParamsService],
    },
    {
      provide: PageWrongConfig,
      useFactory: pageWrongFactory,
    },
    {
      provide: PageNotFoundConfig,
      useValue: <PageNotFoundConfig>{
        mainUrl: '/home',
        logoComponent: LogoComponent,
      },
    },
    Title,
    { provide: UniversalGridApiService, useClass: AuthUniversalGridApiService },
    {
      provide: UniversalGridUtilsService,
      useClass: AuthUniversalGridUtilsService,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
